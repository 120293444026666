const InfoNotification = {
  functional: true,
  props: {
    text: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  watch: {
    text () { this.showInfo() },
  },
  methods: {
    showInfo () {
      if (!this.text) {
        return
      }
      this.$toast[this.type || 'info'](this.text, { position: 'top-right', duration: 8000, allowHtml: true })
    },
  },
  template: '<span></span>',
}

export default InfoNotification
