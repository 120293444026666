/* global Vue, GLOSSARY, Swiper, SwiperSlide */

import { Navigation, Pagination } from 'swiper'
import ShowMore from 'scripts/apps/components/ShowMore'
import Accordion from 'scripts/apps/components/Accordion'

const glossaryApp = Vue.createApp({
  name: 'GlossaryApp',
  template: '#glossaryTemplate',
  components: { Swiper, SwiperSlide, Accordion, ShowMore },
  setup () {
    const INITIAL_SHOWING_COUNT = 2
    const showNoAccordions = Vue.ref(INITIAL_SHOWING_COUNT)
    const glossary = Vue.reactive({})
    const selectedLetter = Vue.ref('a')
    const initSwiper = Vue.ref(false)

    const accordions = Vue.computed(() => {
      return glossary[selectedLetter.value]?.slice(0, showNoAccordions.value)
    })

    const selectLetter = (letter) => {
      if (glossary[letter].length) { selectedLetter.value = letter }
      showNoAccordions.value = INITIAL_SHOWING_COUNT
    }

    Vue.onMounted(() => {
      'abcčdefghijklmnoprsštuvzž'.split('').forEach(letter => {
        glossary[letter] = GLOSSARY.filter(definition => {
          return definition.name.charAt(0).toLowerCase() === letter
        })
      })
      initSwiper.value = true
    })

    return {
      glossary,
      accordions,
      selectedLetter,
      selectLetter,
      initSwiper,
      showNoAccordions,
      INITIAL_SHOWING_COUNT,
      swiperModules: [Navigation, Pagination],
    }
  },
})

if (document.getElementById('glossary-app')) {
  glossaryApp.mount('#glossary-app')
}

;
/* global Vue, CONTENT_PAGES  */

const searchApp = Vue.createApp({
  name: 'SearchApp',
  template: '#searchTemplate',
  setup () {
    const showNoOfResultsPerPage = 4
    const searchTerm = Vue.ref('')
    const showSearch = Vue.ref(false)
    const currentPage = Vue.ref(1)
    const showingWhichResults = Vue.ref('')
    const searchPages = Vue.ref([])
    const input = Vue.ref(null)

    const body = document.querySelector('body')
    const mobileMenu = document.querySelector('#mobile-menu')
    const openSearchAppElements = document.querySelectorAll('.search-app-icon')
    openSearchAppElements.forEach(btn => {
      btn.addEventListener('click', e => {
        showSearch.value = true
        body.classList.add('no-overflow')
        mobileMenu.checked = false
        Vue.nextTick(() => {
          input.value.focus()
        })
      })
    })

    const goToPage = (type) => {
      if (type === 'next') {
        currentPage.value = currentPage.value + 1 > Math.ceil(filteredResults.value.length / showNoOfResultsPerPage) ? Math.ceil(filteredResults.value.length / showNoOfResultsPerPage) : currentPage.value + 1
      } else {
        currentPage.value = currentPage.value - 1 < 1 ? 1 : currentPage.value - 1
      }
    }

    const filteredResults = Vue.computed(() => {
      currentPage.value = 1
      if (!searchPages.value || !searchTerm.value) {
        return searchPages.value
      }
      const search = searchTerm.value.toLowerCase()
      return searchPages.value.filter((item) => {
        return checkArray(item, search)
      })
    })

    const filteredResultsToShow = Vue.computed(() => {
      const end = currentPage.value * showNoOfResultsPerPage
      const start = end - showNoOfResultsPerPage
      if (searchTerm.value) {
        showingWhichResults.value = filteredResults.value.length ? `Pronađeno ${start + 1}-${end} od ${filteredResults.value.length} za: ${searchTerm.value}` : `Nema rezultata za: ${searchTerm.value}`
      } else {
        showingWhichResults.value = 'Unesite vaš upit.'
      }
      return filteredResults.value.slice(start, end)
    })

    const checkArray = (item, search) => {
      const keys = Object.keys(item)
      let returnVal = false
      for (let ii = 0; ii < keys.length && !returnVal; ii++) {
        if (item[keys[ii]] === null || item[keys[ii]] === undefined) {
          continue
        }
        const varType = typeof item[keys[ii]]
        if (varType === 'string' || varType === 'number') {
          const stringVal = String(item[keys[ii]]).toLowerCase()
          if (stringVal.indexOf(search) !== -1) {
            returnVal = true
          }
        } else if (varType === 'object') {
          returnVal = checkArray(item[keys[ii]], search)
        }
      }
      return returnVal
    }

    const highlightSearchString = (description) => {
      if (!description) return ''

      const regex = new RegExp(searchTerm.value.toLowerCase(), 'ig')
      let parsed = description
      const matches = [...description.matchAll(regex)]
      matches.forEach(match => {
        parsed = description.replaceAll(match, `<span class="button button--tag inline">${match}</span>`)
      })
      return parsed
    }

    Vue.onMounted(() => {
      searchPages.value = CONTENT_PAGES.filter((object, index) => {
        return !object.params.inactive && index === CONTENT_PAGES.findIndex(obj => {
          return obj.url === object.url
        })
      })
    })

    return {
      input,
      searchTerm,
      filteredResults,
      filteredResultsToShow,
      currentPage,
      showNoOfResultsPerPage,
      showingWhichResults,
      showSearch,
      mobileMenu,
      body,
      goToPage,
      highlightSearchString,
    }
  },
})

if (document.getElementById('search-app')) {
  searchApp.mount('#search-app')
}

;
/* global Vue, VueToast */

import InfoNotification from 'scripts/apps/components/InfoNotification'

const createInfoDisclaimerApp = mountEl => Vue.createApp({
  name: 'createInfoDisclaimerApp',
  components: { InfoNotification },
  template: `
    <span class="icon icon-tooltip" @click="showTooltip()"></span>
    <InfoNotification :text="tooltipText" :type="type" />
  `,
  setup (props) {
    const tooltipText = Vue.ref('')
    const type = Vue.ref('info')
    const showTooltip = () => {
      tooltipText.value = mountEl.dataset.message
      if (mountEl.dataset.type) type.value = mountEl.dataset.type
      setTimeout(() => { tooltipText.value = '' }, 20)
    }

    Vue.onMounted(() => {
      if (mountEl.classList.contains('is-hidden')) showTooltip()
    })

    return {
      type,
      tooltipText,
      showTooltip,
    }
  },
})

window.showAllNotifications = () => {
  Array.from(document.getElementsByClassName('info-disclaimer-app')).forEach(element => {
    const app = createInfoDisclaimerApp(element)
    app.use(VueToast)
    app.mount(element)
    if (element.classList.contains('is-hidden')) setTimeout(() => { app.unmount() }, 2000)
  })
}
window.showAllNotifications()
