export default {
  name: 'Accordion',
  template: `
<details class="accordion">
  <summary>
    <div>
      <span class="accordion__name">{{ title }}</span>
      <span class="accordion__plus"></span>
    </div>
  </summary>

  <div class="accordion__content">
    {{ content }}
  </div>
</details>
  `,
  props: { title: String, content: String },
}
